import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { IRestaurantInfo, RestaurantPost } from '../../interfaces/restaurant';
import { Chain, ChainPost } from '../../interfaces/chain';
import { UserUpdate } from '../../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public restaurantDataSubject: BehaviorSubject<any>;
  public restaurantData: Observable<any>;


  constructor(
    private http: HttpClient
  ) {
    this.restaurantDataSubject = (new BehaviorSubject(null) as BehaviorSubject<any>);
    this.restaurantData = this.restaurantDataSubject.asObservable();
  }

  public addRestaurant(restaurant: RestaurantPost): Observable<any> {
    return this.http.post('/restaurants', restaurant);
  }

  public getAllRestaurants(): Observable<IRestaurantInfo[]> {
    return this.http.get<IRestaurantInfo[]>(`/restaurants`);
  }

  public getRestaurantsByChainId(chainId: number): Observable<IRestaurantInfo[]> {
    const params = new HttpParams().set('chainId', chainId.toString());
    return this.http.get<IRestaurantInfo[]>(`/restaurants/`, { params });
  }

  public getRestaurantInfo(id: number): Observable<IRestaurantInfo> {
    return this.http.get<IRestaurantInfo>(`/restaurants/${id}`);
  }

  public updateRestaurantInfo({
      id,
      name,
      address,
      contactPhone,
      wifiName,
      wifiPassword,
      province,
      dwallGroupId,
      language,
      adRunEvery,
      adVastTagUrl,
      adVistarMediaApiKey,
      adVistarMediaNetworkId,
      adVistarMediaVenueId
    }: Partial<IRestaurantInfo>): Observable<any> {
    return this.http.patch(`/restaurants/${id}`, {
      name,
      address,
      contactPhone,
      wifiName,
      wifiPassword,
      language,
      adRunEvery,
      adVastTagUrl,
      province,
      dwallGroupId,
      adVistarMediaApiKey,
      adVistarMediaNetworkId,
      adVistarMediaVenueId
    });
  }

  public getUserInfo(): Observable<any> {
    const RestaurantDataObservable = this.http.get<any>(`/users/me`)
      .pipe(share());

    RestaurantDataObservable
      .subscribe((data: any) => {
        this.restaurantDataSubject.next(data);
      });
    return RestaurantDataObservable;
  }

  public removeRestaurant(id: number): Observable<{message: string}> {
    return this.http.delete<{message: string}>(`/restaurants/${id}`);
  }

  public getAllChains(): Observable<Chain[]> {
    return this.http.get<Chain[]>(`/chains`);
  }

  public getChainById(chainId: number): Observable<Chain> {
    return this.http.get<Chain>(`/chains/${chainId}`);
  }

  public addNewChain(newChain: ChainPost): Observable<ChainPost> {
    return this.http.post<ChainPost>('/chains', newChain);
  }

  public updateUser(id: number, user: UserUpdate): Observable<Chain> {
    return this.http.patch<Chain>(`/chains/${id}`, user);
  }

  public deleteChain(id: number): Observable<{message: string}> {
    return this.http.delete<{message: string}>(`/chains/${id}`);
  }

}
