<div class="container">
  <div class="title-wrap">
    <h1 class="title">{{ 'pages.menu.title' | translate }}</h1>
    <div class="actions" *ngIf="editable">
      <button class="btn btn__primary" *ngIf="!isEdit" [routerLink]="['/restaurants', restaurantId, 'special-offer']">
        {{ 'pages.menu.actions.specialOffer' | translate }}
      </button>
      <button type="button" (click)="createMenu()" *ngIf="isEdit && tabIndex !==1" class="btn btn__primary btn__icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round" />
          <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
            stroke-linejoin="round" />
        </svg>
        <span class="add">{{ 'pages.menu.actions.add' | translate }}</span>
      </button>
      <button type="button" *ngIf="!isEdit; else toggleEdit" class="btn btn__icon btn__secondary"
        (click)="isEdit = true">
        <span>{{ 'pages.menu.actions.manage' | translate }}</span>
      </button>
      <ng-template #toggleEdit>
        <button type="button" class="btn btn__icon btn__active" (click)="isEdit = false">
          <span>{{ 'pages.menu.actions.done' | translate }}</span>
        </button>
      </ng-template>
    </div>
  </div>

  <div class="menu__skeleton" *ngIf="isLoading; else content">
    <div class="menu__skeleton-loader" *ngFor="let i of [].constructor(2)">
      <div class="menu__skeleton-row img">
        <div class="menu__skeleton-field img">
          <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="menu__skeleton-row info">
        <div class="menu__skeleton-field info">
          <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="menu__skeleton-row info">
        <div class="menu__skeleton-field info">
          <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>

  <ng-template #content>
    <div class="menu__wrapper">
      <div class="menu__not-found" *ngIf="!isLoading && !menus.length">
        <span>{{ 'pages.menu.noMenu' | translate }}</span>
      </div>

      <app-menu *ngFor="let menu of menus" [menu]="menu" [isEdit]="isEdit" [isMainMenu]="true" [restaurantId]="restaurantInfoData.id"></app-menu>
    </div>
  </ng-template>
</div>
