import { Component, OnInit } from '@angular/core';
import { IChainMenu } from '../../../interfaces/menu';
import { NotifierService } from 'angular-notifier';
import { MatDialog } from '@angular/material/dialog';
import { MenuStoreService } from '../../../services/menu/menu-store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddChainMenuComponent } from '../../../components/modals/add-chain-menu/add-chain-menu.component';
import { AdminService } from '../../../services/admin/admin.service';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { pluck, tap } from 'rxjs/operators';
import { Chain } from '../../../interfaces/chain';
import { ActivatedRoute } from '@angular/router';
import { WebsocketService } from '../../../services/websocket/websocket.service';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-chain-menu-page',
  templateUrl: './chain-menu-page.component.html',
  styleUrls: ['./chain-menu-page.component.scss']
})
export class ChainMenuPageComponent implements OnInit {
  public chain!: Chain;
  public isEdit = false;
  public isLoading = false;
  public chainId!: string;
  public tabIndex = 0;
  public allRestaurants: IRestaurantInfo[] = [];
  public chainMenus$ = new BehaviorSubject<IChainMenu[]>([]);
  public editable = false;

  constructor(
    private activateRoute: ActivatedRoute,
    private notify: NotifierService,
    private dialog: MatDialog,
    private menuStore: MenuStoreService,
    private adminService: AdminService,
    private websocket: WebsocketService,
    public auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.activateRoute.parent?.data.pipe(
      pluck('chain'),
      tap(chain => {
        this.editable = !this.auth.isViewer(chain);
        this.chain = chain;
        this.initData();
      }),
      untilDestroyed(this),
    ).subscribe();
    this.initWebsocket();
  }

  private initWebsocket(): void {
    this.websocket.inboundMessage$.pipe(
      untilDestroyed(this)
    ).subscribe((message: any) => {
      if (message.type === 'updateMenu') {
        this.menuStore.fetchChainMenu(this.chain.id, message.data.id);
      }
    });
  }

  public getRestaurants(chainId: number): void {
    this.adminService.getRestaurantsByChainId(chainId).subscribe((restaurants) => {
      this.allRestaurants = restaurants;
    });
  }

  public initData(): void {
    this.menuStore.fetchChainMenus({
      chainId: this.chain.id,
      menuType: 'mainMenu',
    });

    this.menuStore.getChainMenus$.pipe(
      untilDestroyed(this)
    ).subscribe((menus) => {
      this.chainMenus$.next(menus);
    });

    this.menuStore.isLoading$.pipe(
      untilDestroyed(this)
    ).subscribe((load) => {
      this.isLoading = load;
    });

    this.getRestaurants(this.chain.id);
  }

  public createChainMenu(): void {
    this.dialog.open(AddChainMenuComponent, {
      data: { chainId: this.chain.id, happyHours: false, allRestaurants: this.allRestaurants },
    });
  }
}
