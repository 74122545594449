import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin/admin.service';
import { AnyIntegration, IDeviceSchedule, IRestaurantInfo } from '../../interfaces/restaurant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {filter, pluck, share, tap} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth/auth.service';
import { nullIfEmptyValidator, wifiNameAndPasswordValidator } from '../../shared/custom-validators';
import { ConfirmationDialogComponent } from '../../components/modals/confirm-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddIntegrationComponent } from '../../components/modals/add-integration/add-integration.component';
import { RestaurantService } from '../../services/restaurant/restaurant.service';
import { EditIntegrationComponent } from '../../components/modals/edit-integration/edit-integration.component';
import * as moment from 'moment/moment';
import { Clipboard } from '@angular/cdk/clipboard';

const compareObjects = (initialForm: FormGroup) =>
  Object.entries(initialForm.value)
    .filter(([key, value]) => initialForm?.get(key)?.dirty)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

@UntilDestroy()
@Component({
  selector: 'app-restaurant-account-page',
  templateUrl: './restaurant-account-page.component.html',
  styleUrls: ['./restaurant-account-page.component.scss']
})
export class RestaurantAccountPageComponent implements OnInit {

  public restaurantInfo: FormGroup = this.generateForm();
  public loading = false;
  public isAvailable = false;
  public restaurantId!: number;
  public restaurantInfoData!: IRestaurantInfo;
  public integrations: AnyIntegration[] = [];
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;
  public publicUrl: string | null = null;
  public workingHours: IDeviceSchedule[] = [];
  public editable = false;
  private initialWorkingHours: IDeviceSchedule[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private activateRoute: ActivatedRoute,
    private notifier: NotifierService,
    private matDialog: MatDialog,
    private adminService: AdminService,
    private restaurantService: RestaurantService,
    public auth: AuthService,
    private clipboard: Clipboard
  ) {
  }

  ngOnInit(): void {
    this.activateRoute.parent?.data.pipe(
      pluck('restaurant'),
      tap(restaurant => {
        this.editable = !this.auth.isViewer(restaurant);
        this.restaurantId = restaurant.id;
        this.publicUrl = restaurant.publicUrl;
        this.getRestaurantById(restaurant.id);
        this.getAllIntegration();
        this.isAvailable = this.auth.isSuperAdmin(restaurant);
      }),
      untilDestroyed(this),
    ).subscribe(() => {
    });

    this.workingHours = moment.weekdays().map((day: string, index: number) => {
      return {
        day: index,
        name: day,
        active: false,
        timeFrom: null,
        timeTo: null
      };
    });

    this.restaurantService.getWorkingHours(this.restaurantId).pipe(
      untilDestroyed(this),
    ).subscribe(workHours => {
      this.updateWorkingHours(workHours);
      this.initialWorkingHours = JSON.parse(JSON.stringify(this.workingHours));
    });
  }

  private generateForm(): FormGroup {
    const urlRegex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~[\\]+=\\-/,]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    const phoneRegex = new RegExp(/^\+?\d+$/);
    return this.formBuilder.group({
      name: [''],
      email: [''],
      address: [''],
      contactPhone: ['', [Validators.pattern(phoneRegex)]],
      wifiName: ['', [Validators.maxLength(50)]],
      wifiPassword: ['', [Validators.maxLength(64)]],
      language: [''],
      dwallGroupId: [''],
      province: [''],
      adRunEvery: ['', [Validators.maxLength(255)]],
      adVistarMediaApiKey: ['', [Validators.maxLength(255)]],
      adVistarMediaNetworkId: ['', [Validators.maxLength(255)]],
      adVistarMediaVenueId: ['', [Validators.maxLength(255)]],
      adVastTagUrl: [null, [Validators.pattern(urlRegex), nullIfEmptyValidator]],
    }, {validators: [wifiNameAndPasswordValidator]});
  }

  getRestaurantInfo(restaurant: IRestaurantInfo): void {
    this.restaurantInfo.patchValue({
      name: restaurant?.name,
      email: restaurant?.email,
      address: restaurant?.address,
      contactPhone: restaurant?.contactPhone,
      dwallGroupId: restaurant?.dwallGroupId,
      province: restaurant?.province,
      wifiName: restaurant?.wifiName,
      wifiPassword: restaurant?.wifiPassword,
      language: restaurant?.language,
      adRunEvery: restaurant?.adRunEvery,
      adVastTagUrl: restaurant?.adVastTagUrl,
      adVistarMediaApiKey: restaurant?.adVistarMediaApiKey,
      adVistarMediaNetworkId: restaurant?.adVistarMediaNetworkId,
      adVistarMediaVenueId: restaurant?.adVistarMediaVenueId,
    });
  }

  updateRestaurantInfo(form: FormGroup): void {
    this.loading = true;

    const changedValues: { [key: string]: any } = compareObjects(form);

    if (changedValues.hasOwnProperty('contactPhone')) {
      changedValues.contactPhone = changedValues.contactPhone.toString();
    }

    this.adminService.updateRestaurantInfo({ id: this.restaurantInfoData?.id, ...changedValues })
      .subscribe((restaurant) => {
      this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.infoChangeSuccess'));
      this.loading = false;
      this.restaurantInfo.reset(restaurant);
    }, () => {
      this.loading = false;
    });
  }

  public getRestaurantById(restaurantId: number): void {
    this.adminService.getRestaurantInfo(restaurantId).subscribe((restaurant) => {
      this.restaurantInfoData = restaurant;
      this.getRestaurantInfo(restaurant);
    });
  }

  public confirmToRemoveRestaurant(): void {
    const title = this.translate.instant('pages.restaurantAccount.deleteRestaurantTitle');
    const message = this.translate.instant('pages.restaurantAccount.deleteRestaurant');

    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe((): void => {
      this.removeRestaurant(this.restaurantInfoData?.id);
    });
  }

  public removeRestaurant(restaurantId: number): void {
    this.adminService.removeRestaurant(restaurantId).subscribe((data) => {

      const successMessage = `${this.translate.instant('pages.restaurantAccount.removedSuccessfullyPart1')} '${this.restaurantInfoData.name}' ${this.translate.instant('pages.restaurantAccount.removedSuccessfullyPart2')}`;
      this.notifier.notify('success', successMessage);

      this.router.navigate(['/chains', this.restaurantInfoData.chainId]);
    });
  }

  workingHoursSubmit(): void {
    if (!this.hasWorkingHoursChanges()) {
      this.notifier.notify('info', this.translate.instant('pages.restaurantAccount.messages.noChanges'));
      return;
    }

    const selectedDays = this.workingHours.filter((day: IDeviceSchedule) => day.timeFrom && day.timeTo).map((day: IDeviceSchedule) => {
      const setSelectTimeFrom = moment(day.timeFrom, 'HH:mm');
      const setSelectTimeTo = moment(day.timeTo, 'HH:mm');
      return {
        day: day.day,
        active: day.active,
        timeFrom: setSelectTimeFrom.format('HH:mm'),
        timeTo: setSelectTimeTo.format('HH:mm')
      };
    });

    this.restaurantService.uploadWorkingHours({
      id: this.restaurantId,
      workSchedule: selectedDays
    }).pipe(share()).subscribe((resp) => {
      this.updateWorkingHours(resp);
      this.initialWorkingHours = JSON.parse(JSON.stringify(this.workingHours));
      this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.messages.workingHours'));
    });
  }

  updateWorkingHours(items: IDeviceSchedule[]): void {
    this.workingHours.map((day: IDeviceSchedule) => {
      day.timeTo = null;
      day.timeFrom = null;
      day.active = false;
      items.map((item: IDeviceSchedule) => {
        if (day.day === item.day) {
          day.timeTo = moment(item.timeTo, 'HH:mm').format('HH:mm');
          day.timeFrom = moment(item.timeFrom, 'HH:mm').format('HH:mm');
          day.active = item.active;
        }
      });
    });
  }

  hasWorkingHoursChanges(): boolean {
    if (JSON.stringify(this.workingHours) !== JSON.stringify(this.initialWorkingHours)) {
      return this.workingHours.every(day => {
        if (day.active) {
          return (day.timeFrom && day.timeTo);
        }
        return true;
      });
    }
    return false;
  }

  public downloadQr(): void {
    const parentElement = parent.document.getElementById('qrElement');
    const svgElement = parentElement?.querySelector('canvas')?.toDataURL('image/png');

    if (svgElement) {

      const blobData = this.convertBase64ToBlob(svgElement);

      const blob = new Blob([blobData], { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      link.download = this.restaurantInfoData.name + 'PublicMenu';
      link.click();

      window.URL.revokeObjectURL(url);
    }
  }

  private convertBase64ToBlob(Base64Image: string): Blob {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);

    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: imageType });
  }

  public addIntegration(): void {
    const addIntegration = this.matDialog.open(AddIntegrationComponent, {
      data: {
        restaurantId: this.restaurantId,
      },
    });

    addIntegration.afterClosed().pipe(
      filter(Boolean)
    ).subscribe((data: any) => {
      this.integrations = [...this.integrations, data];
    });
  }
  public getAllIntegration(): void {
    this.restaurantService.getAllIntegration(this.restaurantId).subscribe((integrations: AnyIntegration[]) => {
      this.integrations = integrations;
    });
  }

  public editIntegration(integrationId: string): void {
    const editIntegration = this.matDialog.open(EditIntegrationComponent, {
      data: {
        restaurantId: this.restaurantId,
        integrationId
      },
    });

    editIntegration.afterClosed().pipe(
      filter(Boolean)
    ).subscribe((data: any) => {
      if (typeof data === 'string') {
        this.integrations = this.integrations
          .filter(integration => integration.id !== data);
      } else {
        this.integrations = this.integrations
          .map(integration => {
            return (integration.id === data.id ? data : integration);
          });
      }
    });
  }

  copyLink(): void {
    this.clipboard.copy(this.publicUrl as string);
    this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.linkCopied'));
  }
}
