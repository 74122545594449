<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.restaurants.modal.addNewRestaurant' | translate }}</h1>
  <form class="modal__form"
        [formGroup]="addRestaurantForm"
        (submit)="addRestaurant(addRestaurantForm)"
  >
    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.restaurants.modal.name' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="name">
      <mat-error *ngIf="addRestaurantForm.controls.name.touched">
        <span *ngIf="addRestaurantForm.controls.name.errors?.required">
          {{ 'pages.restaurants.modal.required' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.restaurants.modal.email' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="email">
      <mat-error *ngIf="addRestaurantForm.controls.email.touched && addRestaurantForm.controls.email.invalid">
        <span *ngIf="addRestaurantForm.controls.email.errors?.required">
          {{ 'pages.restaurants.modal.required' | translate }}
        </span>
        <span *ngIf="addRestaurantForm.controls.email.errors?.email">
          {{ 'pages.restaurants.modal.validEmail' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.restaurants.modal.password' | translate }}</mat-label>
      <input type="password" matInput formControlName="password">
      <mat-error *ngIf="addRestaurantForm.controls.password.touched && addRestaurantForm.controls.password.invalid">
        <span *ngIf="addRestaurantForm.controls.password.errors?.required">
          {{ 'pages.restaurants.modal.required' | translate }}
        </span>
        <span *ngIf="addRestaurantForm.controls.password.errors?.minlength">
          {{ 'pages.restaurants.modal.passwordMinLength' | translate }}
        </span>
        <span *ngIf="addRestaurantForm.controls.password.errors?.maxlength">
          {{ 'pages.restaurants.modal.passwordMaxLength' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.restaurants.modal.province' | translate }}</mat-label>
      <input type="text" matInput formControlName="province">
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.restaurants.modal.dwallGroupId' | translate }}</mat-label>
      <input type="number" matInput formControlName="dwallGroupId">
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.restaurantAccount.defaultLanguage' | translate }}</mat-label>
      <mat-select placeholder="{{ 'pages.restaurantAccount.selectLanguage' | translate }}" formControlName="language">
        <mat-option value="en-CA">
          {{ 'languages.en-CA.label' | translate }}
        </mat-option>
        <mat-option value="fr-CA">
          {{ 'languages.fr-CA.label' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="modal__submit-btn">
      <button class="btn btn__primary" type="submit" [disabled]="addRestaurantForm.invalid">
        <ng-container *ngIf="loading; else createRestaurant">
          <mat-spinner diameter="22" color="secondary"></mat-spinner>
        </ng-container>
        <ng-template #createRestaurant>{{ 'pages.restaurants.modal.createRestaurant' | translate }}</ng-template>
      </button>
    </div>
  </form>
</div>
