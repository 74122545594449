import { Component, OnInit } from '@angular/core';
import { Chain } from '../../interfaces/chain';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from '../../services/admin/admin.service';
import { pluck, tap } from 'rxjs/operators';
import { AddSpecialComponent } from '../../components/modals/add-special/add-special.component';
import { IRestaurantInfo } from '../../interfaces/restaurant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISpecial} from '../../interfaces/special';
import { SpecialsStoreService } from '../../services/specials/specials-store.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuthService } from '../../services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-specials-page',
  templateUrl: './specials-page.component.html',
  styleUrls: ['./specials-page.component.scss']
})
export class SpecialsPageComponent implements OnInit {
  private specialNewSequence: number[] = [];
  public currentChain!: Chain;
  public isEdited = false;
  public allRestaurants: IRestaurantInfo[] = [];
  public allSpecials: ISpecial[] = [];
  public isLoading = false;
  public specialsSequence: number[] = [];
  public startDate = '';
  public endDate = '';
  public status = '';
  public selectedRestaurantId: number[] | null = null;
  public isFiltered = false;
  public editable = false;


  constructor(
    private readonly activateRoute: ActivatedRoute,
    private readonly matDialog: MatDialog,
    private readonly adminService: AdminService,
    private readonly specialsStore: SpecialsStoreService,
    private readonly auth: AuthService
  ) { }

  ngOnInit(): void {
    this.getCurrentChain();
  }

  private getCurrentChain(): void {
    this.activateRoute.parent?.data.pipe(
      pluck('chain'),
      tap(chain => {
        this.editable = !this.auth.isViewer(chain);
        this.currentChain = chain;
        this.initData();
      })
    ).subscribe();
  }

  myFilterEnd = (d: Date | null): boolean => {
    if (!d || !this.startDate) {
      return true;
    }
    return d >= new Date(this.startDate);
  }

  myFilterStart = (d: Date | null): boolean => {
    if (!d || !this.endDate) {
      return true;
    }
    return d <= new Date(this.endDate);
  }

  public getRestaurants(chainId: number): void {
    this.adminService.getRestaurantsByChainId(chainId).subscribe((restaurants) => {
      this.allRestaurants = restaurants;
    });
  }
  initData(): void {
    this.specialsStore.fetchSpecials(this.currentChain.id);

    this.specialsStore.getSpecials$.pipe(
      untilDestroyed(this)
    ).subscribe((specials) => {
      this.allSpecials = specials;
      this.specialsSequence = specials.map(special => special.sequence);
    });

    this.specialsStore.isLoading$.pipe(
      untilDestroyed(this)
    ).subscribe((load) => {
      this.isLoading = load;
    });

    this.specialsStore.sequence$.pipe(
      untilDestroyed(this)
    ).subscribe((sequence) => {
      this.specialsSequence = sequence;
      this.specialNewSequence = sequence;
    });

    this.getRestaurants(this.currentChain.id);
  }

  public edit(): void {
    this.isEdited = !this.isEdited;

    if (!this.isEdited) {
      this.specialsStore.updateSpecialSequence(this.currentChain.id, this.specialNewSequence);
    }
  }
  public openAddSpecial(): void {
    this.matDialog.open(AddSpecialComponent, {
      data: {
        chainId: this.currentChain.id,
        allRestaurants: this.allRestaurants
      }
    });
  }

  dropSpecial($event: CdkDragDrop<ISpecial>): void {
    moveItemInArray(this.allSpecials, $event.previousIndex, $event.currentIndex);
    this.specialNewSequence = this.allSpecials.map(special => special.id);
  }

  public applyFilters(startDate?: string, endDate?: string, status?: string, restaurantId?: number[]): void {
    this.isFiltered = true;

    const filters: any = {};

    if (startDate) {
      filters.scheduleStartFrom =  new Date(startDate).toISOString();
    }
    if (endDate) {
      filters.scheduleEndTo = this.specialsStore.adjustEndDate(new Date(endDate));
    }
    if (status) {
      filters.status = status;
    }
    if (restaurantId) {
      filters.restaurantId = restaurantId;
    }

    this.specialsStore.fetchSpecials(this.currentChain.id, filters);
  }

  public resetFilters(): void {
    this.isFiltered = false;
    this.startDate = '';
    this.endDate = '';
    this.status = '';
    this.selectedRestaurantId = null;

    this.specialsStore.fetchSpecials(this.currentChain.id);
  }

}
