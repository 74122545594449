import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddWaiterComponent } from '../../components/modals/add-waiter/add-waiter.component';
import { filter, pluck, tap } from 'rxjs/operators';
import { RestaurantService } from '../../services/restaurant/restaurant.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EditWaiterComponent } from '../../components/modals/edit-waiter/edit-waiter.component';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { IRestaurantInfo } from '../../interfaces/restaurant';
import { AuthService } from '../../services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-waiters-page',
  templateUrl: './waiters-page.component.html',
  styleUrls: ['./waiters-page.component.scss']
})
export class WaitersPageComponent implements OnInit {

  public allWaiters: any[] = [];
  public restaurantId: number | undefined;
  public restaurant!: IRestaurantInfo;
  public isEdited = false;
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;
  public editable = false;
  public showTables = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private notifier: NotifierService,
    private translate: TranslateService,
    private restaurantService: RestaurantService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.parent?.data.pipe(
      pluck('restaurant'),
      tap(restaurant => {
        this.editable = !this.auth.isViewer(restaurant);
        this.restaurant = restaurant;
        this.restaurantId = restaurant.id;
        this.getAllWaiters(restaurant.id);
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  addWaiter(): void {
    const addWaiter = this.dialog.open(AddWaiterComponent, {
      data: {
        restaurantId: this.restaurantId
      }
    });

    addWaiter.afterClosed().pipe(
      filter(Boolean)
    ).subscribe((data) => {
      this.allWaiters = [data, ...this.allWaiters];
    });
  }

  editWaiter(waiterId: string): void {
    const editWaiter = this.dialog.open(EditWaiterComponent, {
      data: { restaurantId: this.restaurantId, waiterId }
    });

    editWaiter.afterClosed().pipe(
      filter(Boolean)
    ).subscribe((data: any) => {
      this.allWaiters = this.allWaiters.map(waiter => (waiter.id === data.id ?  data : waiter));
    });
  }

  getAllWaiters(id: number): void {
    this.restaurantService.getAllWaiters(id).subscribe((waiters) => {
      this.allWaiters = waiters.reverse();
    });
  }

  removeWaiter(restaurantId: number, staffId: number): void {
    this.restaurantService.removeWaiter(restaurantId, staffId).pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.allWaiters = this.allWaiters.filter((item) => item.id !== staffId);
      this.notifier.notify('success', this.translate.instant('pages.waiters.messages.deletedWaiter'));
    });
  }
}
