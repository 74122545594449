<div class="container">
  <div class="title-wrap">
    <div class="title-wrap__main">
    <h1 class="title">{{ 'pages.restaurants.title' | translate }}</h1>
    <div class="actions" *ngIf="editable">
      <button class="btn btn__primary btn__icon" *ngIf="isEdited" (click)="openAddRestaurant()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
        <span class="add">{{ 'pages.restaurants.actions.add' | translate }}</span>
      </button>
      <button class="btn btn__icon" [ngClass]="{'btn__secondary': !isEdited, 'btn__active': isEdited}" (click)="isEdited = !isEdited">
        <span *ngIf="!isEdited">{{ 'pages.restaurants.actions.manage' | translate }}</span>
        <span *ngIf="isEdited">{{ 'pages.restaurants.actions.done' | translate }}</span>
      </button>
    </div>
    </div>
    <app-search
      class="search"
      (searchValue)="searchRestaurants($event)"
      [searchStr]="this.searchTerm$.getValue()"
      [placeholder]="'pages.restaurants.actions.searchRestaurants' | translate"
    ></app-search>
  </div>
  <div class="empty" *ngIf="isEmpty">
    <span>{{ 'pages.restaurants.empty' | translate }}</span>
  </div>
  <div class="empty" *ngIf="!isEmpty && !(filteredRestaurants$ | async)?.length">
    <span>{{ 'pages.restaurants.emptySearch' | translate }}</span>
  </div>
  <div class="restaurant-list">
    <div class="restaurant-list__header">
      <div class="item">{{ 'pages.restaurants.name' | translate }}</div>
      <div class="item">{{ 'pages.restaurants.email' | translate }}</div>
      <div class="item">{{ 'pages.restaurants.dwallGroupId' | translate }}</div>
      <div class="item">{{ 'pages.restaurants.province' | translate }}</div>
    </div>
    <div
      class="restaurant-list__item"
      *ngFor="let restaurant of filteredRestaurants$ | async"
      id="{{restaurant.id}}"
      [routerLink]="['/restaurants', restaurant.id, 'tables']"
    >
      <div class="item-column">{{restaurant.name}}</div>
      <div class="item-column">{{restaurant.email}}</div>
      <div class="item-column group-id">{{restaurant.dwallGroupId || '—'}}</div>
      <div class="item-column">{{restaurant.province || '—'}}</div>
    </div>
  </div>
</div>
