import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from '../../services/modal/modal.service';
import { AdminService } from '../../services/admin/admin.service';
import { RestaurantService } from '../../services/restaurant/restaurant.service';
import { filter, pluck, tap } from 'rxjs/operators';
import { AddTableComponent } from '../modals/add-table/add-table.component';
import { TableService } from '../../services/table/table.service';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ITable } from 'src/app/interfaces/table';
import { TranslateService } from '@ngx-translate/core';
import { EditTableComponent } from '../modals/edit-table/edit-table.component';
import { IRestaurantInfo } from '../../interfaces/restaurant';

@UntilDestroy()
@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit {

  @Input() editable = false;
  @Input() isEdited = false;
  @Input() selectedTables: Set<number> | undefined;

  @Output() selectedTablesChange = new EventEmitter<{event: boolean, id: number}>();

  public restaurant!: IRestaurantInfo;
  public isWaiter: boolean | undefined;
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;

  constructor(
    public auth: AuthService,
    public table: TableService,
    private dialog: ModalService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private adminService: AdminService,
    private restaurantService: RestaurantService,
    private notifier: NotifierService,
    private activateRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activateRoute.parent?.data.pipe(
      pluck('restaurant'),
      tap(restaurant => {
        this.restaurant = restaurant;
        this.isWaiter = this.auth.isWaiter(restaurant);
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  addTable(): void {
    const addTable = this.matDialog.open(AddTableComponent, {
      data: {
        restaurantId: this.restaurant.id
      }
    });

    addTable.afterClosed().pipe(
      filter(Boolean),
    ).subscribe(() => {
      this.notifier.notify('success', this.translate.instant('pages.tables.messages.addTable'));
    });
  }

  cancelCalled(table: ITable): void {
    if (this.isEdited) { return; }

    if (table.status) {
      return this.table.cancelCalled(table);
    }

    if (table.statusPay) {
      return this.table.cancelCalledPay(table);
    }

    if (table.statusRepeat) {
      return this.table.cancelCalledRepeat(table);
    }
  }

  openTableEditModal(table: ITable): void {
    const tableSettings = this.matDialog.open(EditTableComponent, {
      data: {
        table
      },
      panelClass: 'settings-modal'
    });

    tableSettings.afterClosed().pipe(
      filter(Boolean),
    ).subscribe(() => {
      this.notifier.notify('success', this.translate.instant('pages.tables.messages.editedTable'));
    });
  }

  checkTables(event: boolean, id: number): void {
   this.selectedTablesChange.emit({event, id});
  }
}
