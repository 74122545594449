<div class="container" xmlns="http://www.w3.org/1999/html">
  <div class="wrap">
    <h1 class="title restaurant-title">{{ 'pages.restaurantAccount.title' | translate }}</h1>
    <form class="modal__form info"
          [formGroup]="restaurantInfo"
          (submit)="updateRestaurantInfo(restaurantInfo)"
    >
      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.name' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="name">
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.email' | translate }}</mat-label>
        <input readonly matInput appFormInputTrim formControlName="email">
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.address' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="address">
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.phone' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="contactPhone">
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.dwallGroupId' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="dwallGroupId">
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.province' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="province">
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.wifiName' | translate }}</mat-label>
        <input type="text" appFormInputTrim matInput formControlName="wifiName">
        <mat-error *ngIf="restaurantInfo.get('wifiName')?.errors?.maxlength && restaurantInfo.get('wifiName')?.touched">
          {{ 'pages.restaurantAccount.required50' | translate }}
        </mat-error>
        <mat-error *ngIf="restaurantInfo.controls.wifiName.errors?.required">
          {{ 'pages.restaurantAccount.wifiValidation' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.wifiPass' | translate }}</mat-label>
        <input type="text" matInput formControlName="wifiPassword">
        <mat-error *ngIf="restaurantInfo.get('wifiPassword')?.errors?.maxlength && restaurantInfo.get('wifiPassword')?.touched">
          {{ 'pages.restaurantAccount.required64' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.defaultLanguage' | translate }}</mat-label>
        <mat-select placeholder="{{ 'pages.restaurantAccount.selectLanguage' | translate }}" formControlName="language">
          <mat-option value="en-CA">
            {{ 'languages.en-CA.label' | translate }}
          </mat-option>
          <mat-option value="fr-CA">
            {{ 'languages.fr-CA.label' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="isAvailable">
        <mat-form-field class="login-wrap__text">
          <mat-label>{{ 'pages.restaurantAccount.adRunEvery' | translate }}</mat-label>
          <input matInput appFormInputTrim formControlName="adRunEvery">
          <mat-error *ngIf="restaurantInfo.get('adRunEvery')?.errors && restaurantInfo.get('adRunEvery')?.touched">
            {{ 'pages.restaurantAccount.maxLengthValidation' | translate }}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <div class="btn-wrap">
        <button class="btn btn__primary"
                type="submit"
                [disabled]="restaurantInfo.invalid || !restaurantInfo.dirty || !editable"
        >
          {{ 'pages.restaurantAccount.save' | translate }}
        </button>

        <button *ngIf="['chains', 'chain'].includes(auth.area()) && editable"
                class="btn btn__warning"
                type="button"
                (click)="confirmToRemoveRestaurant()"
        >
          {{ 'pages.restaurantAccount.deleteRestaurantTitle' | translate }}
        </button>
      </div>
    </form>

      <div class="working-hours">
        <div class="working-hours__label">
          <h3>{{ 'pages.restaurantAccount.workingHours' | translate }}</h3>
        </div>
        <div class="working-hours__picker" *ngFor="let day of workingHours; let i = index">
          <ng-container>
            <mat-checkbox
              class="example-margin"
              [(ngModel)]="day.active"
              name="active[{{day.day}}]"
            >
              {{ 'pages.happyHours.days.' + day.name | translate }}
            </mat-checkbox>
            <div class="hours-picker">
              <mat-form-field class="hours__input">
                <input
                  matInput
                  [(ngModel)]="day.timeFrom"
                  name="timeFrom[{{day.day}}]"
                  placeholder="{{ 'pages.restaurantAccount.from' | translate }}"
                  type="time"
                  [max]="day.timeTo"
                  [disabled]="!day.active"
                >
              </mat-form-field>
              <mat-form-field class="hours__input">
                <input
                  matInput
                  [(ngModel)]="day.timeTo"
                  name="timeTo[{{day.day}}]"
                  placeholder="{{ 'pages.restaurantAccount.to' | translate }}"
                  type="time"
                  [min]="day.timeFrom"
                  [disabled]="!day.active"
                  #timeTo="ngModel"
                />
              </mat-form-field>
            </div>
          </ng-container>
        </div>

        <button
          type="submit"
          class="btn btn__primary"
          (click)="workingHoursSubmit()"
          [disabled]="!hasWorkingHoursChanges() || !editable"
        >
          {{ 'pages.restaurantAccount.save' | translate }}
        </button>
      </div>

    <ng-container *ngIf="isAvailable || !editable">
      <div class="integrations">
        <div class="integration__label">
          <h3>{{ 'pages.restaurantAccount.integrations' | translate }}</h3>
          <button  *ngIf="isAvailable && editable"
                   class="btn btn__primary btn__icon"
                   (click)="addIntegration()"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
              <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <span class="add">{{ 'pages.restaurantAccount.addIntegration' | translate }}</span>
          </button>
        </div>

        <div class="integration header">
          <span class="integration__item">{{ 'pages.restaurantAccount.provider' | translate }}</span>
          <span class="integration__item">{{ 'pages.restaurantAccount.credentials' | translate }}</span>
        </div>

        <div class="integration" *ngFor="let integration of integrations">
          <ng-container *ngIf="integration.provider === 'VAST'">
            <span class="integration--item">{{integration.provider}}</span>
            <div class="integration--item credentials">
              <div class="integration--item--ell">
                <sub>{{ 'pages.restaurantAccount.adVastTagUrl' | translate }}</sub>
                <span>{{integration.credentials.vastTagUrl}}</span>
              </div>

            </div>
            <div class="integration--item integration__control" *ngIf="editable">
              <button class="btn btn__icon edit-integration" (click)="editIntegration(integration.id)">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" stroke="transparent" width="24"  height="24" viewBox="0 0 24 24" style=" fill:#000000;">
                  <path d="M 18.414062 2 C 18.158062 2 17.902031 2.0979687 17.707031 2.2929688 L 15.707031 4.2929688 L 14.292969 5.7070312 L 3 17 L 3 21 L 7 21 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.926094 2.0979687 18.670063 2 18.414062 2 z M 18.414062 4.4140625 L 19.585938 5.5859375 L 18.292969 6.8789062 L 17.121094 5.7070312 L 18.414062 4.4140625 z M 15.707031 7.1210938 L 16.878906 8.2929688 L 6.171875 19 L 5 19 L 5 17.828125 L 15.707031 7.1210938 z"></path>
                </svg>
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="integration.provider === 'vistarMedia'">
            <span class="integration--item">Vistar Media</span>
            <div class="integration--item credentials">
              <div class="integration--item--ell">
                <sub>{{ 'pages.restaurantAccount.adApiKey' | translate }}</sub>
                <span>******</span>
              </div>
              <div class="integration--item--ell">
                <sub>{{ 'pages.restaurantAccount.adNetworkId' | translate }}</sub>
                <span>{{integration.credentials.networkId}}</span>
              </div>
              <div class="integration--item--ell">
                <sub>{{ 'pages.restaurantAccount.adVenueId' | translate }}</sub>
                <span>{{integration.credentials.venueId}}</span>
              </div>
            </div>
            <div class="integration--item integration__control" *ngIf="editable">
              <button class="btn btn__icon edit-integration" (click)="editIntegration(integration.id)">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" stroke="transparent" width="24"  height="24" viewBox="0 0 24 24" style=" fill:#000000;">
                  <path d="M 18.414062 2 C 18.158062 2 17.902031 2.0979687 17.707031 2.2929688 L 15.707031 4.2929688 L 14.292969 5.7070312 L 3 17 L 3 21 L 7 21 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.926094 2.0979687 18.670063 2 18.414062 2 z M 18.414062 4.4140625 L 19.585938 5.5859375 L 18.292969 6.8789062 L 17.121094 5.7070312 L 18.414062 4.4140625 z M 15.707031 7.1210938 L 16.878906 8.2929688 L 6.171875 19 L 5 19 L 5 17.828125 L 15.707031 7.1210938 z"></path>
                </svg>
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="integration.provider === 'kelseysMenu'">
            <span class="integration--item">{{integration.provider}}</span>
            <div class="integration--item credentials">
              <div class="integration--item--ell">
                <sub>{{ 'pages.restaurantAccount.storeNumber' | translate }}</sub>
                <span>{{integration.credentials.storeNumber}}</span>
              </div>

            </div>
            <div class="integration--item integration__control" *ngIf="editable">
              <button class="btn btn__icon edit-integration" (click)="editIntegration(integration.id)">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" stroke="transparent" width="24"  height="24" viewBox="0 0 24 24" style=" fill:#000000;">
                  <path d="M 18.414062 2 C 18.158062 2 17.902031 2.0979687 17.707031 2.2929688 L 15.707031 4.2929688 L 14.292969 5.7070312 L 3 17 L 3 21 L 7 21 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.926094 2.0979687 18.670063 2 18.414062 2 z M 18.414062 4.4140625 L 19.585938 5.5859375 L 18.292969 6.8789062 L 17.121094 5.7070312 L 18.414062 4.4140625 z M 15.707031 7.1210938 L 16.878906 8.2929688 L 6.171875 19 L 5 19 L 5 17.828125 L 15.707031 7.1210938 z"></path>
                </svg>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="qr-container" *ngIf="this.publicUrl">
      <h3>{{ 'pages.restaurantAccount.qrTitle' | translate }}</h3>
        <div class="qr__img">
          <qrcode class="qr"
                  id="qrElement"
                  [qrdata]="this.publicUrl"
                  [width]="160"
                  [errorCorrectionLevel]="'M'"
                  [margin]="0"
          ></qrcode>
          <div class="description">
            <div class="description__label">{{ 'pages.restaurantAccount.copyLink' | translate }}</div>
            <div
              class="link__container"
              (click)="copyLink()"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path d="M17.5 14H19a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v1.5M5 10h7a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7c0-1.1.9-2 2-2Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span class="link">{{this.publicUrl}}</span>
            </div>
            <div class="description__label">{{ 'pages.restaurantAccount.orDownload' | translate }}</div>
            <button  *ngIf="isAvailable"
                     class="btn btn__secondary btn__icon"
                     (click)="downloadQr()"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="3 3 18 18" fill="none">
                <path d="M5.256 16A5.33 5.33 0 0 1 3 11.65C3 9.2 4.8 6.936 7.5 6.5 8.347 4.486 10.351 3 12.69 3c2.994 0 5.442 2.323 5.61 5.25 1.59.695 2.7 2.4 2.7 4.247a4.5 4.5 0 0 1-2 3.745M12 21V11m0 10-3-3m3 3 3-3" stroke="#ffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span class="add">{{ 'pages.restaurantAccount.download' | translate }}</span>
            </button>
          </div>
        </div>
    </div>
  </div>
</div>
