import { Component, OnInit } from '@angular/core';
import { Chain } from '../../interfaces/chain';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, pluck, tap} from 'rxjs/operators';
import { ModalService } from '../../services/modal/modal.service';
import { AddChainComponent } from '../../components/modals/add-chain/add-chain.component';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { AuthService } from '../../services/auth/auth.service';
import { IRestaurantInfo } from 'src/app/interfaces/restaurant';

@UntilDestroy()
@Component({
  selector: 'app-chains-page',
  templateUrl: './chains-page.component.html',
  styleUrls: ['./chains-page.component.scss'],
})
export class ChainsPageComponent implements OnInit {

  public allChains!: Chain[];
  public isEdited = false;
  public editable = false;

  constructor(
    private router: Router,
    private dialog: ModalService,
    private activateRoute: ActivatedRoute,
    private notifier: NotifierService,
    private translate: TranslateService,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.activateRoute.data.pipe(
      pluck('chains'),
      tap(chains => {
        this.editable = chains.some((chain: Chain) => this.auth.isAdmin(chain));
        this.allChains = chains.reverse();
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  public openAddChain(): void {
    const addingChainModal = this.dialog.openDialog<AddChainComponent, Chain>(AddChainComponent);

    addingChainModal.afterClosed().pipe(
      filter((data) => !!data?.id)
    ).subscribe((data: Chain): void => {
      this.allChains = [data, ...this.allChains];
      this.notifier.notify('success', this.translate.instant('pages.chains.messages.chainAdded'));
    });
  }
}
